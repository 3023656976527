var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-grid-categories"},[_c('b-container',[_c('div',{staticClass:"component-grid-categories__title"},[_c('h2',[_vm._v("Explore by category")])]),_vm._v(" "),_c('ul',{staticClass:"component-grid-categories__grid"},_vm._l((_vm.categories),function(category){return _c('li',{key:category.id},[_c('router-link',{staticClass:"card-category",attrs:{"to":{
            name: 'jobs',
            query: {
              roles: category.roles,
              countryCode: category.countryCode,
              sorting: category.sorting
            }
          }}},[_c('img',{attrs:{"src":_vm.icon,"alt":"Magnifying glass"}}),_vm._v(" "),_c('h5',[_vm._v(_vm._s(category.name))])])],1)}),0),_vm._v(" "),_c('img',{staticClass:"component-grid-categories__pattern",attrs:{"src":require("assets/components/jobs/categories-pattern.svg"),"alt":"pattern"}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }