<template>
  <div class="featured_startups">
    <b-container>
      <div class="featured_startups-header">
        <h2 style="">Featured startups</h2>
        <div class="featured_startups-subheader">
          <span class="featured_startups-subheader-text"> Find your second home.</span>
          <LinkArrow :link="`/startups${allCountriesQueryParams}`">Show all startups</LinkArrow>
        </div>
      </div>

      <div class="featured_startups-active_startups">
        <div v-for="startup in activeStartups" :key="startup.id">
          <!-- <transition name="fade" mode="out-in"> -->
          <div class="featured_startups-active_startup_wrapper">
            <div class="featured_startups-active_startup-logo_wrapper">
              <img
                class="featured_startups-active_startup-logo"
                :src="startup.logo"
                :alt="startup.name"
              />
            </div>

            <h4 class="featured_startups-active_startup-title">{{ startup.name }}</h4>
            <div class="featured_startups-active_startup-description">
              {{ startup.whatWeDo }}
            </div>
            <div class="featured_startups-active_startup-details">
              <div class="featured_startups-active_startup-details-industies">
                {{ formattedIndustriesText(startup.industries) }}
              </div>
              <div class="featured_startups-active_startup-details-jobs_count">
                {{ startup.numberOfActiveJobs || 0 }} jobs
              </div>
            </div>
            <!-- <a class="featured_startups-active_startup_a" :href="`/startups/${startup.key}`"></a> -->
            <router-link
              class="featured_startups-active_startup_a"
              :to="`/startups/${startup.key}`"
            ></router-link>
          </div>
          <!-- </transition> -->
        </div>
      </div>
      <img
        src="~/assets/components/page-home/small_pattern.svg"
        class="featured_startups-pattern"
        alt="pattern"
      />
    </b-container>
  </div>
</template>

<script>
import { fetchFeaturedStartups } from './homePageHelpers';
import LinkArrow from '@/components/LinkArrow';

export default {
  name: 'FeaturedStartups',
  components: {
    LinkArrow
  },
  props: {
    prefetchedActiveFeaturedStartups: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    activeStartupAmount: 3,
    startupsLoaded: false,
    featuredStartups: [],
    activeFeaturedStartups: []
  }),
  computed: {
    allCountriesQueryParams() {
      const allCountries = this.$store.getters['meta/siteCountries'];
      const parsedQueryParams = allCountries
        .filter((country) => country.value !== 'NI')
        .map((country) => `countryCodes=${country.value}`);
      return `?${parsedQueryParams.join('&')}`;
    },
    activeStartups() {
      return this.activeFeaturedStartups?.length > 0
        ? this.activeFeaturedStartups
        : this.prefetchedActiveFeaturedStartups || [];
    },
    countryCode() {
      return this.$store.getters['meta/siteIpDetectedCountryCode'] || 'DK';
    }
  },
  methods: {
    formattedIndustriesText(industriesObject) {
      if (!industriesObject) {
        return '';
      }

      const industries = Object.keys(industriesObject).filter((key) => industriesObject[key]);

      const formattedIndustries = industries.map((industry) =>
        this.$store.getters['meta/industryByValue'](industry)
      );

      return formattedIndustries.map((industry) => industry.label).join(', ');
    },
    getActiveFeaturedStartups(num) {
      let arrCopy = [...this.featuredStartups];
      let result = [];

      for (let i = 0; i < num; i++) {
        let randomIndex = Math.floor(Math.random() * arrCopy.length);
        result.push(arrCopy[randomIndex]);
        arrCopy.splice(randomIndex, 1);
      }

      return result;
    }
  },
  async created() {
    let featuredStartups = await fetchFeaturedStartups({ axios: this.$axios });
    this.featuredStartups = featuredStartups;
    this.startupsLoaded = true;
    if (featuredStartups.length > this.activeStartupAmount) {
      this.changeStartupInterval = setInterval(async () => {
        this.activeFeaturedStartups = this.getActiveFeaturedStartups(this.activeStartupAmount);
      }, 5000);
    }
  }
};
</script>

<style scoped lang="scss">
.featured_startups {
  width: 100wv;
  overflow: hidden;
  background-color: $gray-100;
  padding: rem(80px) 0;

  @include media-breakpoint-down(lg) {
    padding: rem(60px) 0;
  }

  @include media-breakpoint-down(sm) {
    padding: rem(50px) 0;
  }
}

.featured_startups-header {
  margin-bottom: rem(40px);
  @include media-breakpoint-down(sm) {
    margin-bottom: rem(16px);
  }

  h2 {
    letter-spacing: rem(-1.5px);
    font-size: rem(48px);
    line-height: 1.6;

    @include media-breakpoint-down(sm) {
      font-size: rem(24px);
    }
  }
}

.featured_startups-subheader {
  display: flex;
}

.featured_startups-active_startups {
  position: relative;
  z-index: 2;
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-columns: minmax(1fr, 1fr);
  // grid-auto-flow: column;

  @include media-breakpoint-only(lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-only(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.featured_startups-active_startup_wrapper {
  width: 100%;
  height: 20rem;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.5625rem;
  word-break: break-word;
  position: relative;
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 60px 90px -30px rgba(31, 36, 48, 0.2);
    @include transition(0.2s);
  }
}
.featured_startups-active_startup_a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.featured_startups-active_startup-logo_wrapper {
  width: 4.5rem;
  height: 4.5rem;
  overflow: hidden;
}
.featured_startups-active_startup-logo {
  // width: 4.5rem;
  // height: 4.5rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.featured_startups-active_startup-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 0.5rem;
}
.featured_startups-active_startup-description {
  width: 100%;
  // height: 4.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-size: 0.875rem;
  color: #51596c;
}
.featured_startups-active_startup-details {
  width: 100%;
  height: 3rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.featured_startups-active_startup-details-industies {
  width: 65%;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  // color: #51596c !important;
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.featured_startups-active_startup-details-jobs_count {
  display: inline-block;
  color: #443fdb;
  font-weight: 700;
  background-color: rgba(68, 63, 219, 0.1);
  padding: 0.375rem 0.875rem;
  flex: 0 0 auto;
}

.featured_startups-subheader-text {
  margin-right: 0.6rem;
  color: #51596c;
}

.featured_startups-pattern {
  position: absolute;
  left: -3.7rem;
  bottom: 2rem;
  z-index: 1;

  @include media-breakpoint-only(lg) {
  }

  @include media-breakpoint-only(md) {
    left: -2rem;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}
//////////////////////////////////// animations below
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
