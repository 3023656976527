<template>
  <Container
    class="hero-companies_block_container"
    :bounds-inner-styles="{ 'padding-top': '24px', 'padding-bottom': '2rem' }"
  >
    <div class="hero-companies_block_wrapper">
      <!-- <div class="hero-company-node" style="width: 240px; max-width: unset;">
        Startups we helped hire 🚀
      </div> -->
      <div class="hero-companies_wrapper">
        <div v-for="item in startups" :key="item.id" class="hero-company-node_wrapper">
          <img :src="item.logo" class="hero-company-node" />
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import Container from '@/components/Container';

export default {
  name: 'StartupLogos',
  components: {
    Container
  },
  data() {
    return {
      startups: [
        {
          id: 'company0',
          logo: require('@/assets/components/page-home/startup_logos/clerkio_logo.webp')
        },
        {
          id: 'company1',
          logo: require('@/assets/components/page-home/startup_logos/pleo_logo.webp')
        },
        {
          id: 'company2',
          logo: require('@/assets/components/page-home/startup_logos/superside_logo.webp')
        },
        {
          id: 'company3',
          logo: require('@/assets/components/page-home/startup_logos/swappie_logo.webp')
        },
        {
          id: 'company4',
          logo: require('@/assets/components/page-home/startup_logos/gomore_logo.webp')
        },
        {
          id: 'company5',
          logo: require('@/assets/components/page-home/startup_logos/oda_logo.webp')
        },
        {
          id: 'company6',
          logo: require('@/assets/components/page-home/startup_logos/voi_logo.webp')
        },
        {
          id: 'company7',
          logo: require('@/assets/components/page-home/startup_logos/monta_logo.webp')
        }
        // {
        //   id: 'company6',
        //   logo: require('~/assets/img/logos/humly_2x.png')
        // }
      ]
    };
  },
  methods: {
    // handleButtonClick() {}
  }
};
</script>

<style lang="scss" scoped>
.hero-companies_block_container {
  // padding-top: rem(24px);
  // padding-bottom: 2rem;
  // padding-left: rem(125px);
  // padding-right: rem(125px);
  // @include media-breakpoint-up(xl) {
  //   // max-width: 1360px;
  // }

  // @include media-breakpoint-down(lg) {
  //   padding-left: rem(15px);
  //   padding-right: rem(15px);
  // }

  // @include media-breakpoint-down(md) {
  //   padding-left: rem(15px);
  //   padding-right: rem(15px);
  // }
  background-color: #f5f7fa;
}
.hero-companies_block_wrapper {
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // justify-content: space-between;
  // flex-wrap: wrap;
  width: 100%;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    // display: none;
  }
  @include media-breakpoint-down(sm) {
    // display: none;
  }
}
.hero-companies_wrapper {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  //   width: calc(100% - 240px);
  width: 100%;

  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(4, 1fr);
    // width: calc(100% - 0px);
  }
  @include media-breakpoint-down(xs) {
    // width: calc(100% - 0px);
    // flex-direction: column;
    grid-template-columns: repeat(2, 1fr);
  }
}
.hero-company-node {
  // margin: 0px 10px;
  color: #484f66;
  font-size: rem(16px);
  line-height: rem(26px);
  max-height: rem(30px);
  max-width: rem(90px);
  margin-top: 20px;
  //   filter: invert(44%) sepia(5%) saturate(268%) hue-rotate(177deg) brightness(90%) contrast(86%);
  @include media-breakpoint-down(sm) {
    // max-height: rem(70px);
    // max-width: rem(250px);
    // margin-top: 20px;
  }
}
.hero-company-node_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
