<template>
  <section
    :class="[
      'component-container',
      'component-container--width--' + width,
      'component-container--spacing--' + spacing,
      {
        'component-container--border-bottom': borderBottom,
        'component-container--border-top': borderTop,
        'component-container--plr-none': plrNone
      }
    ]"
    :style="{ backgroundColor: backgroundColor }"
  >
    <b-container class="component-container__bounds" :style="boundsStyles">
      <div
        class="component-container__bounds__inner"
        :style="boundsInnerStyles"
      >
        <slot />
      </div>
    </b-container>
  </section>
</template>

<script>
export default {
  name: 'Container',
  props: {
    boundsInnerStyles: {
      type: Object,
      default: () => ({})
    },
    boundsStyles: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: 'default'
    },
    spacing: {
      type: String,
      default: 'default'
    },
    backgroundColor: {
      type: String
    },
    plrNone: Boolean,
    borderBottom: {
      type: Boolean,
      default: false
    },
    borderTop: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.component-container {
  $root: &;

  &__bounds {
    &__inner {
      padding-top: calc(#{rem(80px)}* var(--scale-spacing));
      padding-bottom: calc(#{rem(80px)}* var(--scale-spacing));

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        padding-top: rem(80px);
        padding-bottom: rem(80px);
      }
    }
  }

  &--width--xs & {
    &__bounds {
      max-width: 700px;
    }
  }

  &--width--small & {
    &__bounds {
      max-width: 800px;
    }
  }

  &--width--medium & {
    &__bounds {
      max-width: 1000px;
    }
  }

  &--width--medium-large & {
    &__bounds {
      max-width: 1360px;
    }
  }

  &--width--big & {
    &__bounds {
      max-width: 100%;
    }
  }

  &--spacing--small & {
    &__bounds__inner {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }

  &--spacing--jobwidget & {
    &__bounds__inner {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  &--spacing--medium & {
    &__bounds__inner {
      padding-top: calc(#{rem(45px)}* var(--scale-spacing));
      padding-bottom: calc(#{rem(45px)}* var(--scale-spacing));

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        padding-top: rem(45px);
        padding-bottom: rem(45px);
      }
    }
  }

  &--spacing--big & {
    &__bounds__inner {
      padding-top: calc(#{rem(160px)}* var(--scale-spacing));
      padding-bottom: calc(#{rem(160px)}* var(--scale-spacing));

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        padding-top: rem(160px);
        padding-bottom: rem(160px);
      }
    }
  }

  &--border-bottom & {
    &__bounds__inner {
      border-bottom: 1px solid $border-color;
    }
  }

  &--border-top & {
    &__bounds__inner {
      border-top: 1px solid $border-color;
    }
  }

  &--plr-none & {
    &__bounds {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>
