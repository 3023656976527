// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/components/jobs/pattern-small.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".view-home__section[data-v-c39157da]{background-color:#f5f7fa;padding:5rem 0}@media (max-width:1199.98px){.view-home__section[data-v-c39157da]{padding:3.75rem 0}}@media (max-width:767.98px){.view-home__section[data-v-c39157da]{padding:3.125rem 0}}.view-home__section[data-v-c39157da]:last-of-type{margin-bottom:0}.view-home__section__header[data-v-c39157da]{margin-bottom:2.5rem;display:flex;align-items:baseline}@media (max-width:767.98px){.view-home__section__header[data-v-c39157da]{margin-bottom:1rem}}.view-home__section__header h2[data-v-c39157da]{font-size:3rem;margin-right:1rem}@media (max-width:767.98px){.view-home__section__header h2[data-v-c39157da]{font-size:1.5rem}}@media (max-width:767.98px){.view-home__section__header span[data-v-c39157da]{font-size:.875rem}}.view-home__section--jobs .view-home__section__pattern[data-v-c39157da]{position:absolute;left:-4rem;top:75%;width:4.0625rem;height:9.5625rem;background:transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%}@media (max-width:767.98px){.view-home__section--jobs .view-home__section__pattern[data-v-c39157da]{display:none}}", ""]);
// Exports
module.exports = exports;
