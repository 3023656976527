<template>
  <section>
    <!-- <PopularSearches /> -->
    <!-- <Panel is-homepage :container="!!user" /> -->
    <HomePageHeroMobile class="mobile_display" />
    <HomePageHero :prefetchedStartup="prefetchedStartup" class="mobile_hide" />

    <StartupLogos />

    <ExploreByCategory />

    <FeaturedStartups :prefetchedActiveFeaturedStartups="prefetchedActiveFeaturedStartups" />

    <div v-for="list in prefetchedPopularJobLists" :key="list.name">
      <PopularStartups :jobs="list.jobs" :name="list.name" :link="list.link" />
    </div>

    <div class="button_wrapper">
      <router-link class="component-search-bar__submit__button btn " to="/jobs">
        View all jobs</router-link
      >
    </div>
  </section>
</template>

<script>
import { buildMetaHead } from '@/helpers/meta';
import {
  fetchStartup,
  fetchFeaturedStartups,
  fetchPopularJobs,
  homePageInit
} from './index/homePageHelpers';
// import PopularSearches from '@/components/PopularSearches';
// import Panel from '@/components/LogoPanel';
import HomePageHeroMobile from '@/components/page-home/PageHomeHero';
import HomePageHero from './index/HomePageHero';
import StartupLogos from './index/StartupLogos';
import ExploreByCategory from './index/ExploreByCategory';
import FeaturedStartups from './index/FeaturedStartups.vue';
import PopularStartups from './index/PopularStartups.vue';

export default {
  name: 'PageHome',
  components: {
    // PopularSearches,
    // Panel,
    HomePageHeroMobile,
    HomePageHero,
    StartupLogos,
    ExploreByCategory,
    FeaturedStartups,
    PopularStartups
  },
  head() {
    return buildMetaHead({
      title: 'Find your next exciting startup job',
      description:
        'We connect global talents with career opportunities in Nordic startups. Search through thousands of startup jobs, across location and remote positions',
      url: process.env.BASE_URL,
      imageUrl: process.env.BASE_URL + '/images/thehub_2022.jpg'
    });
  },
  data: () => ({}),
  computed: {
    countryCode() {
      return this.$store.getters['meta/siteIpDetectedCountryCode'] || 'DK';
    },

    user() {
      return this.$store.state.user.data || 'lene';
    }
  },
  async asyncData({ store, error, $axios }) {
    try {
      const countryCode = store.getters['meta/siteIpDetectedCountryCode'] || 'DK';

      // const [
      //   prefetchedStartup,
      //   prefetchedActiveFeaturedStartups,
      //   prefetchedPopularJobLists
      // ] = await Promise.all([
      //   await fetchStartup({ axios: $axios }),
      //   await fetchFeaturedStartups({
      //     axios: $axios,
      //     limit: 3
      //   }),
      //   await fetchPopularJobs({
      //     axios: $axios,
      //     countryCode
      //   })
      // ]);

      const initData = await homePageInit({ axios: $axios, countryCode });
      // console.log('initData', initData);
      return {
        prefetchedStartup: initData.prefetchedStartup,
        prefetchedActiveFeaturedStartups: initData.prefetchedActiveFeaturedStartups,
        prefetchedPopularJobLists: initData.prefetchedPopularJobLists
      };
    } catch (err) {
      error(err);
    }
  },
  async mounted() {
    await this.$store.dispatch('jobSearch/loadAndCount');
  }
};
</script>

<style scoped lang="scss">
.button_wrapper {
  padding-top: 0rem;
  padding-bottom: 5rem;
  width: 100%;
  background-color: #f5f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile_display {
  @include media-breakpoint-up(sm) {
    display: none;
  }
  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.mobile_hide {
  @include media-breakpoint-up(sm) {
    display: block;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
}
</style>
