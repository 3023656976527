<template>
  <div class="component-grid-categories">
    <b-container>
      <div class="component-grid-categories__title">
        <h2>Explore by category</h2>
      </div>
      <ul class="component-grid-categories__grid">
        <li v-for="category in categories" :key="category.id">
          <router-link
            class="card-category"
            :to="{
              name: 'jobs',
              query: {
                roles: category.roles,
                countryCode: category.countryCode,
                sorting: category.sorting
              }
            }"
          >
            <img :src="icon" alt="Magnifying glass" />

            <h5>{{ category.name }}</h5>
          </router-link>
        </li>
      </ul>
      <img
        src="~/assets/components/jobs/categories-pattern.svg"
        class="component-grid-categories__pattern"
        alt="pattern"
      />
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'ExploreByCategory',
  components: {
    // CardCategory
  },
  data: () => ({}),
  computed: {
    countryCode() {
      return this.$store.getters['meta/siteIpDetectedCountryCode'] || 'DK';
    },
    icon() {
      return require(`@/assets/icons/categories/lookglass-icon.svg`);
    },
    categories() {
      // console.log('this.countryCode', this.countryCode);
      return [
        {
          name: 'Engineering & Product jobs',

          countryCode: this.countryCode,
          sorting: 'newJobs',
          roles: [
            'backenddeveloper',
            'devops',
            'engineer',
            'frontenddeveloper',
            'fullstackdeveloper',
            'mobiledevelopment',
            'productmanagement'
          ]
        },
        {
          name: 'Marketing & Growth jobs',
          countryCode: this.countryCode,
          sorting: 'newJobs',

          roles: ['marketing']
        },
        {
          name: 'Design & Creative jobs',
          countryCode: this.countryCode,
          sorting: 'newJobs',

          roles: ['design', 'uxuidesigner']
        },
        {
          name: 'Data & Analytics jobs',
          countryCode: this.countryCode,
          sorting: 'newJobs',

          roles: ['analyst', 'datascience', 'devops']
        },
        {
          name: 'Operations & Finance Jobs',
          countryCode: this.countryCode,
          sorting: 'newJobs',

          roles: ['finance', 'legal', 'operations', 'projectmanagement']
        },
        {
          name: 'Sales, CS & Business jobs',
          countryCode: this.countryCode,
          sorting: 'newJobs',

          roles: ['businessdevelopment', 'customerservice', 'customersuccess', 'sales']
        },
        {
          name: 'HR & Recruiting jobs',
          countryCode: this.countryCode,
          sorting: 'newJobs',

          roles: ['humanresources']
        },
        {
          name: 'Remote jobs at Nordic startups',
          countryCode: 'REMOTE',
          sorting: 'newJobs',

          roles: []
        },
        {
          name: 'Nordic startup jobs in Europe',
          countryCode: 'EU',
          sorting: 'newJobs',
          roles: []
        }
      ];
    }
  }
};
</script>

<style scoped lang="scss">
.component-grid-categories {
  width: 100wv;
  overflow: hidden;
  background-color: $gray-100;
  padding: rem(80px) 0;

  @include media-breakpoint-down(lg) {
    padding: rem(60px) 0;
  }

  @include media-breakpoint-down(sm) {
    padding: rem(50px) 0;
  }

  &__title {
    margin-bottom: rem(40px);
    letter-spacing: rem(-1.5px);

    @include media-breakpoint-down(sm) {
      margin-bottom: rem(16px);
    }

    h2 {
      font-size: rem(48px);

      @include media-breakpoint-down(sm) {
        font-size: rem(24px);
      }
    }
  }

  &__grid {
    position: relative;
    z-index: 2;
    margin: rem(-8px);
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    li {
      width: calc(20% - 1rem);
      flex: 0 0 calc(20% - 1rem);
      margin: rem(8px);

      @include media-breakpoint-only(md) {
        width: calc(25% - 1rem);
        flex: 0 0 calc(25% - 1rem);
      }

      @include media-breakpoint-down(sm) {
        width: calc(50% - 1rem);
        flex: 0 0 calc(50% - 1rem);
      }

      &:nth-child(5) {
        @include media-breakpoint-only(md) {
          margin-left: calc(25% + #{rem(8px)});
        }
      }

      &:nth-child(6) {
        margin-left: calc(20% + #{rem(8px)});

        @include media-breakpoint-down(md) {
          margin-left: rem(8px);
        }
      }
    }
  }

  &__pattern {
    position: absolute;
    left: rem(-16px);
    top: rem(150px);
    z-index: 1;

    @include media-breakpoint-only(lg) {
      top: rem(144px);
    }

    @include media-breakpoint-only(md) {
      left: rem(-78px);
      top: rem(136px);
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}
.card-category {
  position: relative;
  display: flex;
  background-color: $white;
  color: $gray-900;
  height: rem(100px);
  padding: rem(24px) rem(36px) rem(24px) rem(24px);
  text-decoration: none;
  @include transition(0.2s);

  @include media-breakpoint-only(lg) {
    padding: rem(24px) rem(36px) rem(24px) rem(24px);
  }

  @include media-breakpoint-only(md) {
    padding: rem(24px) rem(36px) rem(24px) rem(24px);
  }

  @include media-breakpoint-down(sm) {
    padding: rem(24px) rem(36px) rem(24px) rem(24px);
  }

  img {
    position: absolute;
    right: rem(13px);
    top: rem(17px);
    height: rem(17px);
    width: rem(13px);
    text-align: right;
    object-fit: contain;
    object-position: center center;
  }

  h5 {
    font-weight: 500;
    letter-spacing: rem(-0.5px);
    line-height: 1.3;

    @include media-breakpoint-down(md) {
      font-size: rem(17px);
    }

    @include media-breakpoint-down(sm) {
      font-size: rem(17px);
    }
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: $gray-900;
    transform: translateY(-1px);
    box-shadow: $box-shadow-light;
  }
}
</style>
