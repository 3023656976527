<template>
  <div class="component-hero-home">
    <b-container class="component-hero-home__title">
      <h1 v-if="user">Welcome home <br />{{ user.name.first }}</h1>

      <h1 v-else>
        Find your next
        <br /><span>exciting</span> startup job
      </h1>
    </b-container>
    <div class="component-hero-home__image-search">
      <b-container>
        <div class="component-hero-home__pattern" :style="{ backgroundImage: `url(${pattern})` }">
          <canvas width="431" height="360"></canvas>
        </div>
        <div class="component-hero-home__search-job-wrapper">
          <SearchBar
            v-model="searchForm"
            big-size
            search-placeholder="Job title or keyword"
            location-placeholder="City, area or select remote"
            include-remote-in-country-selector
            :on-submit="onSearch"
          />
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import SearchBar from '../search/SearchBar';

export default {
  name: 'HeroHome',
  components: {
    SearchBar
  },
  props: {
    user: Object
  },
  data() {
    return {
      searchForm: {
        search: undefined,
        location: undefined,
        countryCode: this.$store.getters['meta/sitePreferredCountryCode']
      }
    };
  },
  computed: {
    pattern() {
      return require('@/assets/components/page-home/pattern-1.svg');
    }
  },
  methods: {
    onSearch() {
      this.$store.dispatch('jobSearch/add', {
        params: this.searchForm,
        createIfNotExists: true
      });

      setTimeout(() => {
        this.$router.push({
          path: '/jobs',
          query: {
            search: this.searchForm.search,
            location: this.searchForm.location,
            countryCode: this.searchForm.countryCode
          }
        });
      }, 250);
    }
  }
};
</script>

<style scoped lang="scss">
.component-hero-home {
  background-color: $dark-blue;
  width: 100wv;
  // overflow: hidden;

  &__title {
    display: flex;
    align-items: center;

    // margin-top: rem(60px);
    padding-top: rem(60px);

    @include media-breakpoint-down(lg) {
      // margin-top: rem(60px);
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
    }

    h1 {
      flex: 0 0 60%;
      color: $white;
      font-size: rem(56px);

      @include media-breakpoint-down(lg) {
        flex: 0 0 74%;
      }

      @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
        font-size: rem(36px);
        margin-bottom: rem(20px);
      }

      span {
        color: $teal;
      }
    }

    p {
      font-size: rem(20px);
      line-height: 1.6;

      @include media-breakpoint-down(lg) {
        font-size: rem(17px);
      }

      a {
        color: $blue-900;
      }
    }
  }

  &__image-search {
    // overflow: hidden;
    padding-top: rem(40px);
    padding-bottom: rem(80px);

    @include media-breakpoint-down(lg) {
      padding-top: rem(60px);
      padding-bottom: rem(100px);
    }

    @include media-breakpoint-down(sm) {
      padding-top: rem(30px);
      padding-bottom: rem(20px);
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      height: 50%;
      background-color: $gray-100;
    }

    .container {
      position: relative;
      z-index: 2;
    }
  }

  &__pattern {
    display: inline-block;
    position: absolute;
    z-index: 2;
    top: rem(-21px);
    left: rem(-6px);
    background-size: cover;
    background-repeat: no-repeat;
    // background-position-y: 37%;
    overflow: hidden;
    height: 17rem;
    background-position-y: 53%;
    width: auto;

    @include media-breakpoint-up(xl) {
      // left: rem(-4px);
      top: rem(-4px);
      height: 15rem;
      width: 28rem;
    }

    @media (max-width: 840px) {
      left: rem(-10px);
      height: 24rem;
    }

    @include media-breakpoint-down(sm) {
      left: rem(-4px);
      height: 19rem;
    }

    canvas {
      @include media-breakpoint-down(sm) {
        width: rem(380px);
      }
    }
  }

  &__search-job-wrapper {
    position: relative;
    z-index: 3;
    padding: rem(20px) 0;

    .component-searchjob {
      @include media-breakpoint-down(sm) {
        padding-top: rem(10px);
        box-shadow: $box-shadow-dark;
      }
    }
  }
}
</style>
