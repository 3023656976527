var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
    'component-container',
    'component-container--width--' + _vm.width,
    'component-container--spacing--' + _vm.spacing,
    {
      'component-container--border-bottom': _vm.borderBottom,
      'component-container--border-top': _vm.borderTop,
      'component-container--plr-none': _vm.plrNone
    }
  ],style:({ backgroundColor: _vm.backgroundColor })},[_c('b-container',{staticClass:"component-container__bounds",style:(_vm.boundsStyles)},[_c('div',{staticClass:"component-container__bounds__inner",style:(_vm.boundsInnerStyles)},[_vm._t("default")],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }