<template>
  <section v-if="jobs.length > 0" class="view-home__section view-home__section--jobs">
    <b-container>
      <div class="view-home__section__pattern"></div>
      <div class="view-home__section__header">
        <h2>{{ name }}</h2>
        <span>
          <LinkArrow :link="link">View all</LinkArrow>
        </span>
      </div>

      <div v-for="job in parsedJobs" :key="job.id" class="my-10">
        <JobsListItem :job="job" />
      </div>
    </b-container>
  </section>
</template>

<script>
import LinkArrow from '@/components/LinkArrow';
import JobsListItem from '@/components/jobs/JobsListItem';
import JobModel from '@/models/Job';
export default {
  name: 'ExploreByCategory',
  components: {
    LinkArrow,
    JobsListItem
  },
  props: {
    name: {
      type: String,
      required: false,
      default: ''
    },
    link: {
      type: String,
      required: false,
      default: ''
    },
    jobs: {
      type: Array,
      required: false,
      default: []
    }
  },
  data: () => ({}),
  mounted() {
    // const countryCode = this.$store.getters['meta/sitePreferredCountryCode'];
    // this.$store.dispatch('jobs/find', {
    //   countryCode
    // });
  },
  computed: {
    parsedJobs() {
      return this.jobs.map((item) => new JobModel(item));
    }
    // jobs() {
    //   const jobs = this.$store?.state?.jobs?.jobs || [];
    //   return jobs?.docs?.slice(0, 6)?.filter((item) => item);
    // }
  }
};
</script>

<style scoped lang="scss">
.view-home {
  &__section {
    background-color: $gray-100;
    padding: rem(80px) 0;
    // padding-bottom: 0px;

    @include media-breakpoint-down(lg) {
      padding: rem(60px) 0;
    }

    @include media-breakpoint-down(sm) {
      padding: rem(50px) 0;
    }

    &:last-of-type {
      margin-bottom: 0;

      // margin-bottom: rem(163px);

      @include media-breakpoint-down(lg) {
        // margin-bottom: rem(143px);
      }

      @include media-breakpoint-down(sm) {
        // margin-bottom: rem(133px);
      }
    }

    &__header {
      margin-bottom: rem(40px);
      display: flex;
      align-items: baseline;

      @include media-breakpoint-down(sm) {
        margin-bottom: rem(16px);
      }

      h2 {
        font-size: rem(48px);
        margin-right: rem(16px);

        @include media-breakpoint-down(sm) {
          font-size: rem(24px);
        }
      }

      span {
        @include media-breakpoint-down(sm) {
          font-size: rem(14px);
        }
      }
    }

    &--jobs & {
      &__pattern {
        position: absolute;
        left: rem(-64px);
        top: 75%;
        width: rem(65px);
        height: rem(153px);
        background: transparent url(~assets/components/jobs/pattern-small.svg) no-repeat center
          center;

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }
}
</style>
